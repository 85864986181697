class Configuration {
	// eslint-disable-next-line sonarjs/cognitive-complexity
	static value (variableName) {
		let result = null
		let environmentVariableValue = Configuration.environmentVariables[variableName]
		if (environmentVariableValue && environmentVariableValue.startsWith('%')) {
			const environmentVariableName = environmentVariableValue.replace(/%/g, '')
			environmentVariableValue = process.env[environmentVariableName]
		}
		if (environmentVariableValue && !environmentVariableValue.startsWith('%')) {
			result = environmentVariableValue
		}
		if (result && result.startsWith('{') && result.endsWith('}')) {
			result = JSON.parse(result)
		} else if (result && ['true'].includes(result.trim().toLowerCase())) {
			result = true
		} else if (result && ['false'].includes(result.trim().toLowerCase())) {
			result = false
		} else if (result && [''].includes(result.trim().toLowerCase())) {
			result = ''
		} else if (result && ['null'].includes(result.trim().toLowerCase())) {
			result = null
		} else if (result && ['undefined'].includes(result.trim().toLowerCase())) {
			result = undefined
		}

		return result
	}

	static get environmentVariables () {
		return {
			VUE_APP_MODE: 'devops',
			VUE_APP_ENV_NAME: 'DEVOPS',
			VUE_APP_SOURCE_MAP: 'true',
			VUE_APP_SENTRY_ENABLE: 'false',
			VUE_APP_SENTRY_CLI_HOST: '%VUE_APP_SENTRY_CLI_HOST%',
			VUE_APP_SENTRY_CLI_TOKEN: '%VUE_APP_SENTRY_CLI_TOKEN%',
			VUE_APP_SENTRY_DSN: '%VUE_APP_SENTRY_DSN%',
			VUE_APP_SENTRY_ATTACH_PROPS: '%VUE_APP_SENTRY_ATTACH_PROPS%',
			VUE_APP_SENTRY_LOG_ERRORS: '%VUE_APP_SENTRY_LOG_ERRORS%',
			VUE_APP_SENTRY_SAMPLE_RATE: '%VUE_APP_SENTRY_SAMPLE_RATE%',
			VUE_APP_SENTRY_TRACE_SAMPLE_RATE: '%VUE_APP_SENTRY_TRACE_SAMPLE_RATE%',
			VUE_APP_SENTRY_HOOKS: '%VUE_APP_SENTRY_HOOKS%',
			VUE_APP_SENTRY_DEDUPE_ENABLE: '%VUE_APP_SENTRY_DEDUPE_ENABLE%',
			VUE_APP_SENTRY_EXTRA_ERROR_DATA_ENABLE: '%VUE_APP_SENTRY_EXTRA_ERROR_DATA_ENABLE%',
			VUE_APP_SENTRY_CAPTURE_CONSOLE_ENABLE: '%VUE_APP_SENTRY_CAPTURE_CONSOLE_ENABLE%',
			VUE_APP_SENTRY_DEBUG_ENABLE: '%VUE_APP_SENTRY_DEBUG_ENABLE%',
			VUE_APP_SENTRY_OFFLINE_ENABLE: '%VUE_APP_SENTRY_OFFLINE_ENABLE%',
			VUE_APP_SENTRY_REPORTING_OBSERVER_ENABLE: '%VUE_APP_SENTRY_REPORTING_OBSERVER_ENABLE%',
			VUE_APP_SENTRY_BROWSER_TRACING_ENABLE: '%VUE_APP_SENTRY_BROWSER_TRACING_ENABLE%',
			VUE_APP_SENTRY_REPLAY_ENABLE: '%VUE_APP_SENTRY_REPLAY_ENABLE%',
			VUE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE: '%VUE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE%',
			VUE_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE: '%VUE_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE%',
			VUE_APP_SENTRY_REPLAY_COLLECT_FONTS: '%VUE_APP_SENTRY_REPLAY_COLLECT_FONTS%',
			VUE_APP_SENTRY_REPLAY_INLINE_IMAGES: '%VUE_APP_SENTRY_REPLAY_INLINE_IMAGES%',
			VUE_APP_SENTRY_REPLAY_INLINE_STYLESHEET: '%VUE_APP_SENTRY_REPLAY_INLINE_STYLESHEET%',
			VUE_APP_SENTRY_REPLAY_MASK_ALL_INPUTS: '%VUE_APP_SENTRY_REPLAY_MASK_ALL_INPUTS%',
			VUE_APP_SENTRY_REPLAY_RECORD_LOG: '%VUE_APP_SENTRY_REPLAY_RECORD_LOG%',
			VUE_APP_SENTRY_REPLAY_RECORD_CANVAS: '%VUE_APP_SENTRY_REPLAY_RECORD_CANVAS%',
			VUE_APP_SENTRY_IGNORE_ERRORS: '%VUE_APP_SENTRY_IGNORE_ERRORS%',
			VUE_APP_ROOT_API: 'https://api.devops.welyb.fr',
			VUE_APP_ROOT_WEBDAV: 'https://dav.devops.welyb.fr',
			VUE_APP_ROOT_WOPI: 'https://wopi.devops.welyb.fr',
			VUE_APP_MAX_COMPANY_NUMBER: '1000000000',
			VUE_APP_UPLOAD_CHUNK_SIZE: '5242880',
			VUE_APP_UPLOAD_MAX_CONCURRENT_CHUNKS: '5',
			VUE_APP_UPLOAD_RETRY_INTERVAL: '30',
			VUE_APP_CHAT_URL: 'https://chat-devops.welyb.fr',
			VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID: '%VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID%',
			VUE_APP_WOPI_BASE_URL: 'https://libreoffice-online.dev.welyb.fr',
			VUE_APP_JITSI_MEET_SCRIPT_URL: 'https://jitsi.welyb.com/external_api.js',
			VUE_APP_JITSI_MEET_SERVER: 'jitsi.welyb.com',
			VUE_APP_ECM_AUTOREFRESH_INTERVAL: '60000',
			VUE_APP_MATOMO_ENABLED: 'false',
			VUE_APP_MATOMO_URL: '%VUE_APP_MATOMO_URL%',
			VUE_APP_MATOMO_SITE_ID: '%VUE_APP_MATOMO_SITE_ID%',
			VUE_APP_MATOMO_TRACKER_FILENAME: '%VUE_APP_MATOMO_TRACKER_FILENAME%',
			VUE_APP_MATOMO_TRACKER_URL: '%VUE_APP_MATOMO_TRACKER_URL%',
			VUE_APP_MATOMO_TRACKER_SCRIPT_URL: '%VUE_APP_MATOMO_TRACKER_SCRIPT_URL%',
			VUE_APP_MATOMO_ENABLE_LINK_TRACKING: '%VUE_APP_MATOMO_ENABLE_LINK_TRACKING%',
			VUE_APP_MATOMO_REQUIRE_CONSENT: '%VUE_APP_MATOMO_REQUIRE_CONSENT%',
			VUE_APP_MATOMO_TRACK_INITIAL_VIEW: '%VUE_APP_MATOMO_TRACK_INITIAL_VIEW%',
			VUE_APP_MATOMO_COOKIES_DISABLE: '%VUE_APP_MATOMO_COOKIES_DISABLE%',
			VUE_APP_MATOMO_HEARTBEAT_TIMER_ENABLE: '%VUE_APP_MATOMO_HEARTBEAT_TIMER_ENABLE%',
			VUE_APP_MATOMO_HEARTBEAT_TIMER_INTERVAL: '%VUE_APP_MATOMO_HEARTBEAT_TIMER_INTERVAL%',
			VUE_APP_MATOMO_DEBUG: '%VUE_APP_MATOMO_DEBUG%',
			VUE_APP_BASE_DOMAIN: '%VUE_APP_BASE_DOMAIN%'
		}
	}

	static get VUE_APP_MODE () {
		return Configuration.value('VUE_APP_MODE')
	}

	static get VUE_APP_ENV_NAME () {
		return Configuration.value('VUE_APP_ENV_NAME')
	}

	static get VUE_APP_SOURCE_MAP () {
		return Configuration.value('VUE_APP_SOURCE_MAP')
	}

	static get VUE_APP_SENTRY_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_ENABLE')
	}

	static get VUE_APP_SENTRY_CLI_HOST () {
		return Configuration.value('VUE_APP_SENTRY_CLI_HOST')
	}

	static get VUE_APP_SENTRY_CLI_TOKEN () {
		return Configuration.value('VUE_APP_SENTRY_CLI_TOKEN')
	}

	static get VUE_APP_SENTRY_DSN () {
		return Configuration.value('VUE_APP_SENTRY_DSN')
	}

	static get VUE_APP_SENTRY_ATTACH_PROPS () {
		return Configuration.value('VUE_APP_SENTRY_ATTACH_PROPS')
	}

	static get VUE_APP_SENTRY_LOG_ERRORS () {
		return Configuration.value('VUE_APP_SENTRY_LOG_ERRORS')
	}

	static get VUE_APP_SENTRY_SAMPLE_RATE () {
		return Configuration.value('VUE_APP_SENTRY_SAMPLE_RATE')
	}

	static get VUE_APP_SENTRY_TRACE_SAMPLE_RATE () {
		return Configuration.value('VUE_APP_SENTRY_TRACE_SAMPLE_RATE')
	}

	static get VUE_APP_SENTRY_HOOKS () {
		return Configuration.value('VUE_APP_SENTRY_HOOKS')
	}

	static get VUE_APP_SENTRY_DEDUPE_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_DEDUPE_ENABLE')
	}

	static get VUE_APP_SENTRY_EXTRA_ERROR_DATA_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_EXTRA_ERROR_DATA_ENABLE')
	}

	static get VUE_APP_SENTRY_CAPTURE_CONSOLE_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_CAPTURE_CONSOLE_ENABLE')
	}

	static get VUE_APP_SENTRY_DEBUG_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_DEBUG_ENABLE')
	}

	static get VUE_APP_SENTRY_OFFLINE_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_OFFLINE_ENABLE')
	}

	static get VUE_APP_SENTRY_REPORTING_OBSERVER_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_REPORTING_OBSERVER_ENABLE')
	}

	static get VUE_APP_SENTRY_BROWSER_TRACING_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_BROWSER_TRACING_ENABLE')
	}

	static get VUE_APP_SENTRY_REPLAY_ENABLE () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_ENABLE')
	}

	static get VUE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE')
	}

	static get VUE_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE')
	}

	static get VUE_APP_SENTRY_REPLAY_COLLECT_FONTS () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_COLLECT_FONTS')
	}

	static get VUE_APP_SENTRY_REPLAY_INLINE_IMAGES () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_INLINE_IMAGES')
	}

	static get VUE_APP_SENTRY_REPLAY_INLINE_STYLESHEET () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_INLINE_STYLESHEET')
	}

	static get VUE_APP_SENTRY_REPLAY_MASK_ALL_INPUTS () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_MASK_ALL_INPUTS')
	}

	static get VUE_APP_SENTRY_REPLAY_RECORD_LOG () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_RECORD_LOG')
	}

	static get VUE_APP_SENTRY_REPLAY_RECORD_CANVAS () {
		return Configuration.value('VUE_APP_SENTRY_REPLAY_RECORD_CANVAS')
	}

	static get VUE_APP_SENTRY_IGNORE_ERRORS () {
		const ignoreErrors = Configuration.value('VUE_APP_SENTRY_IGNORE_ERRORS') ? Configuration.value('VUE_APP_SENTRY_IGNORE_ERRORS').split(',') : []
		return ignoreErrors.map((message) => `/^${message}$/`)
	}

	static get VUE_APP_ROOT_API () {
		return Configuration.value('VUE_APP_ROOT_API')
	}

	static get VUE_APP_ROOT_WEBDAV () {
		return Configuration.value('VUE_APP_ROOT_WEBDAV')
	}

	static get VUE_APP_ROOT_WOPI () {
		return Configuration.value('VUE_APP_ROOT_WOPI')
	}

	static get VUE_APP_MAX_COMPANY_NUMBER () {
		return Configuration.value('VUE_APP_MAX_COMPANY_NUMBER')
	}

	static get VUE_APP_UPLOAD_CHUNK_SIZE () {
		return Configuration.value('VUE_APP_UPLOAD_CHUNK_SIZE')
	}

	static get VUE_APP_UPLOAD_MAX_CONCURRENT_CHUNKS () {
		return Configuration.value('VUE_APP_UPLOAD_MAX_CONCURRENT_CHUNKS')
	}

	static get VUE_APP_UPLOAD_RETRY_INTERVAL () {
		return Configuration.value('VUE_APP_UPLOAD_RETRY_INTERVAL')
	}

	static get VUE_APP_CHAT_URL () {
		return Configuration.value('VUE_APP_CHAT_URL')
	}

	static get VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID () {
		return Configuration.value('VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID')
	}

	static get VUE_APP_WOPI_BASE_URL () {
		return Configuration.value('VUE_APP_WOPI_BASE_URL')
	}

	static get VUE_APP_JITSI_MEET_SCRIPT_URL () {
		return Configuration.value('VUE_APP_JITSI_MEET_SCRIPT_URL')
	}

	static get VUE_APP_JITSI_MEET_SERVER () {
		return Configuration.value('VUE_APP_JITSI_MEET_SERVER')
	}

	static get VUE_APP_ECM_AUTOREFRESH_INTERVAL () {
		return Configuration.value('VUE_APP_ECM_AUTOREFRESH_INTERVAL')
	}

	static get VUE_APP_MATOMO_ENABLED () {
		return Configuration.value('VUE_APP_MATOMO_ENABLED')
	}

	static get VUE_APP_MATOMO_URL () {
		return Configuration.value('VUE_APP_MATOMO_URL')
	}

	static get VUE_APP_MATOMO_SITE_ID () {
		return Configuration.value('VUE_APP_MATOMO_SITE_ID')
	}

	static get VUE_APP_MATOMO_TRACKER_FILENAME () {
		return Configuration.value('VUE_APP_MATOMO_TRACKER_FILENAME')
	}

	static get VUE_APP_MATOMO_TRACKER_URL () {
		return Configuration.value('VUE_APP_MATOMO_TRACKER_URL')
	}

	static get VUE_APP_MATOMO_TRACKER_SCRIPT_URL () {
		return Configuration.value('VUE_APP_MATOMO_TRACKER_SCRIPT_URL')
	}

	static get VUE_APP_MATOMO_ENABLE_LINK_TRACKING () {
		return Configuration.value('VUE_APP_MATOMO_ENABLE_LINK_TRACKING')
	}

	static get VUE_APP_MATOMO_REQUIRE_CONSENT () {
		return Configuration.value('VUE_APP_MATOMO_REQUIRE_CONSENT')
	}

	static get VUE_APP_MATOMO_TRACK_INITIAL_VIEW () {
		return Configuration.value('VUE_APP_MATOMO_TRACK_INITIAL_VIEW')
	}

	static get VUE_APP_MATOMO_COOKIES_DISABLE () {
		return Configuration.value('VUE_APP_MATOMO_COOKIES_DISABLE')
	}

	static get VUE_APP_MATOMO_HEARTBEAT_TIMER_ENABLE () {
		return Configuration.value('VUE_APP_MATOMO_HEARTBEAT_TIMER_ENABLE')
	}

	static get VUE_APP_MATOMO_HEARTBEAT_TIMER_INTERVAL () {
		return Configuration.value('VUE_APP_MATOMO_HEARTBEAT_TIMER_INTERVAL')
	}

	static get VUE_APP_MATOMO_DEBUG () {
		return Configuration.value('VUE_APP_MATOMO_DEBUG')
	}

	static get VUE_APP_BASE_DOMAIN () {
		return Configuration.value('VUE_APP_BASE_DOMAIN')
	}
}

export const Config = Configuration
